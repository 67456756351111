import React from 'react';
import ReactDOM from 'react-dom/client';
import DocumentMeta from 'react-document-meta';
import './index.css';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import AuthProvider from './utils/authProvider'; // Import AuthProvider

const API_BASE_URL = 'https://insightroi.com/api/';

const meta = {
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'SMB ROI, data modeling, AI-driven analytics',
      title: 'InsightROI',
      description:
        'InsightROI provides small and medium-sized businesses (SMBs) with clear, ROI-focused analytics and automated insights across all departments. Leveraging advanced data modeling and AI-driven analytics, InsightROI helps teams turn data into growth without the complexity.',
    },
  },
  canonical: API_BASE_URL,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <DocumentMeta {...meta}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </DocumentMeta>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
