// Format a date as MM/DD/YYYY
export const formatDate = (date) => {
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const year = d.getFullYear();
  return `${month}/${day}/${year}`;
};

// Capitalize the first letter of a string
export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Format a number with commas
export const formatNumber = (number) => {
  if (number == null || isNaN(number)) {
    // Handle null, undefined, or non-numeric values
    return 'N/A';
  }
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

// Password strength validator
export const getPasswordStrength = (password) => {
  if (!password) return '';
  const lengthCriteria = password.length >= 8;
  const lowercase = /[a-z]/.test(password);
  const uppercase = /[A-Z]/.test(password);
  const number = /\d/.test(password);
  const specialChar = /[\W_]/.test(password);

  const passed = [
    lengthCriteria,
    lowercase,
    uppercase,
    number,
    specialChar,
  ].filter(Boolean).length;

  if (passed <= 2) return 'Weak';
  if (passed === 3 || passed === 4) return 'Moderate';
  return 'Strong';
};

// Validate password rules
export const isPasswordValid = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  return regex.test(password);
};
