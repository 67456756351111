import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';

function RightSidebar({
  isAuthenticated,
  messages,
  sendMessage,
  clearChat,
  userMessage,
  setUserMessage,
  isRightSidebarOpen,
  toggleSidebar,
}) {
  const location = useLocation();
  const showRightSidebarPages = ['/data-view']; // Only show sidebar on these pages

  return (
    <>
      {/* Show chevron only when the right sidebar is available */}
      {isAuthenticated && showRightSidebarPages.includes(location.pathname) && (
        <>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {isRightSidebarOpen ? '>' : '<'}
          </div>

          {/* Right Sidebar (Hidden when isSidebarOpen is false) */}
          <div className={`sidebar ${isRightSidebarOpen ? 'active' : ''}`}>
            <h2>ROIbot</h2>
            <div className="chatbox">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  <strong>{msg.sender === 'user' ? 'You' : 'Roi'}:</strong>{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: msg.message || msg.text,
                    }}
                  />
                </div>
              ))}
            </div>
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Type your message..."
              className="chat-input"
            />
            <button onClick={sendMessage} className="chat-button">
              <FaPaperPlane />
            </button>
            <div className="clear-chat">
              <button onClick={clearChat} className="clear-chat-button">
                Click here to clear chat
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RightSidebar;
