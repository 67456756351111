import './App.css';
import React, { useState } from 'react';
import { getPasswordStrength, isPasswordValid } from './utils/formatUtils';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaClipboardList } from 'react-icons/fa';

function ResetPassword() {
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRequirements, setShowRequirements] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!isPasswordValid(newPassword)) {
      setMessage(
        'Password must include 8+ characters, uppercase, lowercase, number, and symbol.'
      );
      return;
    }

    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          username,
          new_password: newPassword,
        }),
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.detail || 'Error resetting password');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="reset-password-wrapper">
      <div className="reset-password-container">
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <p>Password Strength: {passwordStrength}</p>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordStrength(getPasswordStrength(e.target.value));
              }}
              required
            />
            <div className="password-actions">
              <button
                className="blue-pill-button"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              <button
                className="blue-pill-button"
                type="button"
                onClick={() => setShowRequirements(!showRequirements)}
              >
                <FaClipboardList />{' '}
                {showRequirements ? 'Hide Requirements' : 'Show Requirements'}
              </button>
            </div>
            {showRequirements && (
              <ul>
                <li>
                  <strong>Password Requirements</strong>
                </li>
                <ul>
                  <li>At least 8 characters</li>
                  <li>One lowercase letter</li>
                  <li>One uppercase letter</li>
                  <li>One number</li>
                  <li>One special character</li>
                </ul>
              </ul>
            )}
          </div>
          <button type="submit" className="reset-button">
            Reset Password
          </button>
        </form>
        {message && <p className="message">{message}</p>}
        <p className="login-link">
          <a href="/login">Sign in</a>
        </p>
      </div>
    </div>
  );
}

export default ResetPassword;
