import React, { useEffect, useState } from 'react';
import { getCookie } from './utils/cookieUtils.js';
import { useLocation } from 'react-router-dom';

function LeftSidebar({ isAuthenticated, isLeftSidebarOpen, toggleSidebar }) {
  const [companyId, setCompanyId] = useState(null);
  const [companyCount, setCompanyCount] = useState(null);
  const location = useLocation();
  const noShowHomePage = ['/']; // Paths where "Home" should NOT be shown

  useEffect(() => {
    const storedCompanyId = getCookie('company_id');
    const storedCompanyCount = getCookie('company_count');
    setCompanyId(storedCompanyId);
    setCompanyCount(storedCompanyCount);
  }, [location]); // Will re-run every route change

  return (
    <>
      {/* Hamburger Icon (Always Visible) */}
      {isAuthenticated && (
        <div
          className={`hamburger ${isLeftSidebarOpen ? 'left-nav-open' : 'left-nav-closed'}`}
          onClick={toggleSidebar}
        >
          ☰
        </div>
      )}

      {/* Left Sidebar */}
      {isAuthenticated && (
        <div className={`leftbar ${isLeftSidebarOpen ? 'active' : ''}`}>
          <nav>
            <ul>
              {!noShowHomePage.includes(location.pathname) && (
                <li>
                  <a href="/">Home</a>
                </li>
              )}
              {companyCount && companyCount > 0 && (
                <li>
                  <a href="/companies">Companies</a>
                </li>
              )}
              <li>
                <a href="/upload">Upload</a>
              </li>
              {companyId && (
                <li>
                  <a href="/data-view">View Data</a>
                </li>
              )}
              <li>
                <a href="/logout">Logout</a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}

export default LeftSidebar;
