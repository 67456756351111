import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCookie } from './cookieUtils.js';

// Create the authentication context
export const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap around the app
const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const authToken = getCookie('auth_token');
      setIsAuthenticated(!!authToken);
    };

    checkAuth(); // Run on mount

    // Automatically re-check authentication every second
    const interval = setInterval(checkAuth, 1000);

    return () => clearInterval(interval);
  }, []);

  const login = () => setIsAuthenticated(true);
  const logout = () => setIsAuthenticated(false);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
