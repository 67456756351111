import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        const response = await fetch('https://insightroi.com/api/logout', {
          method: 'POST',
          credentials: 'include', // Ensure cookies are included
        });

        if (!response.ok) {
          throw new Error('Failed to log out');
        }

        // console.log('Successfully logged out');
        navigate('/login'); // Redirect to login
      } catch (error) {
        // console.error('Logout error:', error);
        navigate('/login'); // Redirect to login even if logout fails
      }
    };

    performLogout();
  }, [navigate]);

  return (
    <div>
      <h2>Logging Out...</h2>
      <p>You will be redirected shortly.</p>
    </div>
  );
}

export default Logout;
