import './App.css';
import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LeftSidebar from './LeftSidebar.js';
import RightSidebar from './RightSidebar.js';
import MapColumns from './MapColumns';
import Logout from './Logout';
import ResetPassword from './ResetPassword';
import { useAuth } from './utils/authProvider';
import { getCookie } from './utils/cookieUtils.js';

const API_BASE_URL = 'https://insightroi.com/api/';
const PrivateRoute = React.lazy(() => import('./utils/privateRoute'));
const Register = React.lazy(() => import('./Register'));
const Login = React.lazy(() => import('./Login'));
const Companies = React.lazy(() => import('./CompanySelection'));
const DataDisplay = React.lazy(() => import('./DataDisplay'));
const UploadForm = React.lazy(() => import('./UploadFormData'));
const ProcessingUpload = React.lazy(() => import('./ProcessingUpload'));
const FileDownloadHandler = React.lazy(() => import('./utils/download'));

function App() {
  // console.log('App initialized...');

  const { login, logout } = useAuth();
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const { isAuthenticated } = useAuth();
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);

  const sendMessage = () => {
    // console.log('Extracted owner_id from cookie:', getCookie('owner_id'));

    const url = 'https://rasa.insightroi.com/webhooks/rest/webhook';
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sender: 'user',
        message: userMessage,
        metadata: {
          owner_id: getCookie('owner_id'),
          company_id: getCookie('company_id'),
        }, // Extract owner_id, and company_id and company cookie
      }),
      credentials: 'include', // Ensure cookies are sent with the request
    })
      .then((response) => {
        if (!response.ok) {
          // console.error('Failed response:', response);
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setMessages([
          ...messages,
          { sender: 'user', message: userMessage },
          ...data,
        ]);
        setUserMessage('');
      })
      .catch((error) => {
        console.error('Error communicating with Roi:', error);
      });
  };

  const clearChat = () => {
    setMessages([]);
  };

  return (
    <Router>
      <div className="App">
        {/* Left Sidebar */}
        <LeftSidebar
          isAuthenticated={isAuthenticated}
          isLeftSidebarOpen={isLeftSidebarOpen}
          toggleSidebar={() => setIsLeftSidebarOpen(!isLeftSidebarOpen)}
          messages={messages}
          sendMessage={sendMessage}
          clearChat={clearChat}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
        />

        {/* Main Content with Dynamic Margins */}
        <div
          className={`content ${
            isLeftSidebarOpen && isRightSidebarOpen
              ? 'both-open'
              : isLeftSidebarOpen
                ? 'left-open'
                : isRightSidebarOpen
                  ? 'right-open'
                  : 'no-sidebars'
          }`}
        >
          {/* Logo (Always Centered) */}
          <header className="App-header">
            <img
              src="/InsightROI-Logo-fcrgb-circle.jpg"
              alt="InsightROI Logo"
              style={{ width: '300px' }}
            />
          </header>

          <div className="main-content">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/companies" element={<Companies />} />
                <Route
                  path="/data-view"
                  element={
                    <PrivateRoute>
                      <DataDisplay />
                    </PrivateRoute>
                  }
                />
                <Route path="/upload" element={<UploadForm />} />
                <Route
                  path="/map-columns/:uploadId"
                  element={
                    <PrivateRoute>
                      <MapColumns />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/processing/:uploadId"
                  element={
                    <PrivateRoute>
                      <ProcessingUpload />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </div>

        {/* Right Sidebar */}
        <RightSidebar
          isAuthenticated={isAuthenticated}
          isRightSidebarOpen={isRightSidebarOpen}
          toggleSidebar={() => setIsRightSidebarOpen(!isRightSidebarOpen)}
          messages={messages}
          sendMessage={sendMessage}
          clearChat={clearChat}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
        />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <h2>Welcome to InsightROI</h2>
      <p>
        What to do next? Select one of your{' '}
        <a href="/companies">companies to view</a>, upload data, create a new
        company, add a new user...
      </p>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <h2>Page Not Found</h2>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
}

export default App;
