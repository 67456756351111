import './App.css';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const fieldOptions = [
  'Date',
  'Location',
  'Account',
  'Address',
  'Description',
  'Amount',
  'Assets',
  'Liabilities',
  'Equity',
  'Revenue',
  'Expenses',
  'Profit/Loss',
  'Cash Inflows',
  'Cash Outflow',
  'Net Change in Cash',
  'Beginning Equity',
  'Additions',
  'Deductions',
  'Ending Equity',
];

function MapColumns() {
  const { uploadId } = useParams();
  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const [mappings, setMappings] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/upload-headers/${uploadId}`)
      .then((res) => res.json())
      .then((data) => {
        setHeaders(data.headers);
        setLoading(false);
      })
      .catch(() => setError('Failed to load headers'));
  }, [uploadId]);

  const handleMappingChange = (header, value) => {
    const updated = { ...mappings };
    // Remove any existing header mapped to this value
    Object.keys(updated).forEach((key) => {
      if (updated[key] === value) delete updated[key];
    });
    updated[header] = value;
    setMappings(updated);
  };

  const handleSubmit = async () => {
    if (
      Object.values(mappings).length !== new Set(Object.values(mappings)).size
    ) {
      setError('Each field can only be mapped once.');
      return;
    }

    try {
      const response = await fetch(`/api/save-mappings/${uploadId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(mappings),
        credentials: 'include',
      });
      const result = await response.json();
      if (response.ok) {
        // Redirect user to the processing page so they can see in realtime their file processing.
        navigate(`/processing/${uploadId}`);
      } else {
        setError(result.detail || 'Failed to save mappings');
      }
    } catch (e) {
      setError('Error saving mappings');
    }
  };

  if (loading) return <p>Loading headers...</p>;

  return (
    <div className="mapping-container">
      <h2>Map Columns</h2>
      {error && <p className="error">{error}</p>}
      <table>
        <thead>
          <tr>
            <th>File Column</th>
            <th>Map to Field</th>
          </tr>
        </thead>
        <tbody>
          {headers.map((header) => (
            <tr key={header}>
              <td>{header}</td>
              <td>
                <select
                  value={mappings[header] || ''}
                  onChange={(e) => handleMappingChange(header, e.target.value)}
                >
                  <option value="">-- Select Field --</option>
                  {fieldOptions.map((field) => (
                    <option
                      key={field}
                      value={field}
                      disabled={Object.values(mappings).includes(field)}
                    >
                      {field}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleSubmit}>Save Mappings</button>
    </div>
  );
}

export default MapColumns;
